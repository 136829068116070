.image-picker {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding-bottom: 18px;

  &:hover {
    opacity: 0.8;

    .picker-button p {
      color: rgb(36, 108, 255);
    }
  }

  .picker-picked {
    width: 90%;
    height: 210px;
    border: 1px solid rgba(189, 189, 189, 0.24);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    object-fit: cover;
    margin-top: 24px;
  }

  .picker-button {
    display: block;
    width: 100%;
    cursor: pointer;
    position: absolute;
    bottom: 36px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 8px 0;

    p {
      padding: 0;
      margin: 0; // Removes default margin of <p> to ensure centering
      color: rgba(14, 98, 182, 1);

      &.amount {
        font-weight: bold;
        font-size: 1.2em;
        margin-top: 0;
      }

      &.description {
        font-size: 0.8em;
        margin-top: 0;
      }
    }
  }
}

.picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);

  .picker-overlay-container {
    margin-top: 24px;
    height: 90vh; // or any other value that suits your design
    width: 90%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 18px;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto; // This will create a scrollbar if the content overflows vertically
    padding: 16px 0 32px 0; // optional, for inner spacing

    .picker-img {
      width: 43%;
      max-height: 150px;
      object-fit: cover;
      margin: 0 8px 24px 8px;
      border: 3px solid rgba(84, 84, 84, 0.4);
      border-radius: 12px;
    }
  }
}

@media (min-width: 1024px) {

  .picker-picked {
    height: 310px;
  }

  .picker-overlay {

    .picker-overlay-container {
      .picker-img {
        width: 20%;

        &:hover {
          border: 3px solid rgb(28, 139, 197);
          cursor: pointer;
        }
      }
    }
  }


}



