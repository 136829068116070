.checkout {
  background-color: transparent;
  display: flex;
  gap: 12px;
  flex-direction: column;

  h3 {
    font-size: 1.3em;
    padding: 24px 0 6px 0;
    margin: 12px 0 6px 0;
    font-family: "Unica One", sans-serif;
    font-style: normal;
    color: #000;
  }

  .checkout-button {
    margin: 24px;
    padding: 12px 36px;
    text-decoration: none;
    color: #fff;
    background-color: #0c67bb;
    font-size: 1.2em;
    cursor: pointer;
    min-width: 320px;
    text-align: center;
    align-self: center;

    &:hover {
      background-color: rgba(12, 103, 187, 0.84);
    }

    &:active {
      background-color: rgba(0, 135, 255, 0.84);
    }
  }

  .checkout-summary {
    margin-top: 24px;
    border-top: 1px solid #d2d1d1;
    text-align: center;
    padding-bottom: 24px;

    table {
      border-collapse: collapse;
      margin: 0 auto;
      text-align: left;
    }

    tr, th, td {
      border: 1px solid #d9d9d9;
    }

    th {
      font-size: 1em;
      padding: 6px 12px;
    }

    td {
      padding: 6px 12px;
    }

    .table-summary {
      color: #000;

      td:last-of-type {
        font-size: 1.1em;
        color: #000;
      }
    }
  }

  .checkout-form {
    border-top: 1px solid #d2d1d1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;

    .checkout-input {
      text-align: left;
      display: flex;
      flex-direction: column;
      width: 85%;

      label {
        margin: 0 24px;
        font-variant: small-caps;
        font-size: 1em;
      }

      input {
        margin: 0 24px;
        padding: 12px 12px 8px 12px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #9a9a9a;
        outline: none;
        box-shadow: none;
        background-color: rgb(250, 250, 250);
        font-size: 1.1em;

        &:focus {
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom: 1px solid #0f54d7;
        }
      }
    }

    .checkout-privacy {
      width: 80%;
      text-align: justify;

      label {
        font-size: 0.9em;
      }

      a {
        color: #053e6c;
      }
    }
  }

  .checkout-delivery {
    border-top: 1px solid #d2d1d1;

    .checkout-delivery-type {
      display: flex;
      justify-content: center;
      margin: 12px;

      div {
        flex: 1;
        max-width: 200px;
        padding: 12px 0;
        border-top: 3px solid #e1e1e1;
        cursor: pointer;

        &:hover {
          border-top: 3px solid #c7c4c4;
        }

        &.checked {
          border-top: 3px solid #33bb52;
        }
      }
    }

    .pickup-container {
      .pickup-info {
        margin: 12px 32px;
        text-align: justify;
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .invisible {
      display: none !important;
    }

    .visible {
      display: block;
      animation: fadeIn 0.5s ease-in-out; /* Adjust the duration and easing as needed */
    }

    .inpost-container {
      width: 100%;
      text-align: center;

      div {
        margin: 0 auto;
      }

      .inpost-widget-container {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8); // Semi-transparent background
        z-index: 999; // Ensure it is on top of other content
        align-items: center;
        justify-content: center;
        overflow: hidden;


        div {
          overflow: hidden;
          width: 90%;
          height: 90%;
        }

        .close-button {
          height: 70px !important;
          line-height: 70px;
          color: #FFFFFF;
          right: 10px;
          font-variant: all-small-caps;
          font-size: 1.1em;
          cursor: pointer;

          strong {
            padding-left: 4px;
            font-size: 0.9em;
          }
        }
      }

      .inpost-chooser {
        height: auto;
        width: fit-content;
        display: flex;
        align-items: center;
        border: 1px solid #0f69ad;
        margin-top: 18px;
        background-color: #0b4b8c;

        .paczkomat-title {
          padding: 12px 18px;
          margin: 0;
          color: #fff;
          font-family: "Unica One", sans-serif;
          font-style: normal;
        }

        .paczkomat-name {
          text-align: left;
          min-width: 120px;
          background-color: rgba(255, 255, 255, 0.9);
          cursor: pointer;
          margin: 0;
          padding: 12px 30px 12px 12px;
          background-image: url("../../assets/search.png");
          background-repeat: no-repeat;
          background-size: 18px 19px;
          background-position: 95% 50%;

          &:hover {
            background-color: #FFFFFF;
          }
        }
      }

      .delivery-cost-info {
        text-align: left;
        margin-left: 10%;
        font-size: 1em;
      }
    }

    .dpd-container {
      width: 100%;
    }

    .direct-info {
      margin: 24px 0 -12px 0;
    }
  }

  .error {
    border-bottom: 2px solid red !important;
    background-color: rgba(255, 0, 0, 0.16) !important;
  }
}

@media (min-width: 1025px) {
  .checkout-summary {
    table {
      min-width: 760px;
    }
  }

  .checkout-input {
    width: 85%;
    max-width: 600px;
  }

  .checkout {
    .checkout-delivery {

      .pickup-container {
        .pickup-info {
          margin: 12px 15%;
        }
      }

      .inpost-chooser {
        .paczkomat-name {
          min-width: 200px;
        }
      }

      .delivery-cost-info {
        text-align: center !important;
        margin: 18px 0 0 0 !important;
        font-size: 0.8em !important;
      }
    }
  }
}