.basket-item {
  display: flex;
  align-items: center;
  padding: 10px 24px;
  border-bottom: 1px solid #ccc;

  &:hover {
    background-color: rgba(124, 176, 238, 0.06);
  }

  .item-image {
    img {
      width: 100px;
      height: 75px;
      object-fit: cover;
      margin-right: 10px;
      border-radius: 6px;
      border: 1px solid #c2c1c1;
    }
  }

  .item-description {
    text-align: left;
    flex: 1;
    p {
      margin-right: 10px;
      flex-grow: 1;
    }
  }

  .item-price {
    flex: 1;

    p {
      margin-right: 10px;
      white-space: nowrap;
      font-weight: bold;
      font-size: 1.3em;
    }

    span {
      font-weight: bold;
      font-size: 0.7em;

      &:last-of-type {
        margin-left: 6px;
      }
    }
  }

  .item-remove {
    button {
      background: none;
      border: none;
      cursor: pointer;
      width: 50px;
      height: 50px;
      border-radius: 10px;

      &:hover {
        background-color: rgba(255, 0, 0, 0.15);
        border: 1px solid rgba(255, 0, 0, 0.25);
      }

      img {
        width: 25px;
      }
    }
  }
}