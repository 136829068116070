.footer {
  background-color: #dcdcdc;
  color: white;
  text-align: center;
  box-shadow: -4px -4px -4px -4px #000;

  .footer-container {
    max-width: 1100px;
    margin: 0 auto;

    .copyright {
      padding: 18px 0;
      font-weight: bold;
      background-color: #9a9a9a;
      background-image: linear-gradient(to right, #dcdcdc, #f5f5f5, #dcdcdc);
      color: rgba(20, 67, 101, 0.64);

      a {
        color: rgba(29, 104, 164, 0.76);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .socials {
      padding: 24px 0 36px 0;
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent;
      border-image: linear-gradient(to right, #dcdcdc, #ffffff, #dcdcdc) 1;

      h4 {
        color: rgba(5, 62, 108, 0.67);
        padding: 0;
        margin: 0 0 24px;
        font-variant: petite-caps;
        font-weight: bold;
        font-size: 1.2em;
      }

      .social-media-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
        gap: 48px;

        a {
          display: block;
          background-size: 35px 35px;
          background-repeat: no-repeat;
          height: 35px;
          padding: 0 0 0 35px;
          line-height: 35px;
          margin: 0;
          text-decoration: none;

          &:hover {
            opacity: 0.75;
          }
        }

        .instagram {
          background-image: url("../assets/instagram.png");
        }

        .facebook {
          background-image: url("../assets/facebook.png");
        }

        .whatsapp {
          background-image: url("../assets/whatsapp.png");
        }

        .email {
          background-image: url("../assets/email.png");
        }
      }
    }
  }
}

