.basket-popup-content {
  width: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;

  .popup-total-price {
    font-size: 1.1em;
    margin: 0 12px 24px 12px !important;
    padding: 0;
  }
}

.go-to-basket {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.78);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;

  .basket-popup {
    background-color: #d5dde7;
    width: 100%;
    max-width: 450px;
    margin: 0 32px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #939393;

    h2 {
      display: block;
      font-size: 1.3em;
      padding: 12px !important;
      margin: 0;
    }
  }
}

.popup-buttons {
  display: flex;
  flex-direction: row;

  .popup-close, .popup-ok {
    flex: 1;
    display: block;
    padding: 18px 12px;
    color: #525252;
    background-color: #ffffff;
    border-top: 1px solid #c9c9c9;
    cursor: pointer;

    &:hover, &:active, &:focus {
      color: #4467f1;
    }
  }

  .popup-ok {
    border-left: 1px solid #c9c9c9;
    background-color: #7dd777;
    color: #000000;
    font-weight: bold;
    text-decoration: none;
  }

}

.basket-item-popup {
  display: flex;
  align-items: center;
  padding: 24px 12px;

  .item-image {
    img {
      width: 50px;
      height: 40px;
      object-fit: cover;
      margin-right: 10px;
      border-radius: 6px;
      border: 0;
    }
  }

  .item-description {
    text-align: left;
    flex: 5;

    p.product-name {
      margin:0;
      line-height: 40px;
    }
  }

  .item-price {
    flex: 2;

    p {
      margin-right: 10px;
      white-space: nowrap;
      font-weight: bold;
      font-size: 1.2em;
      padding:0;
    }

    span {
      font-weight: bold;
      font-size: 0.7em;

      &:last-of-type {
        margin-left: 6px;
      }
    }
  }

  .item-remove {
    button {
      background: none;
      border: none;
      cursor: pointer;
      width: 50px;
      height: 50px;
      border-radius: 10px;

      &:hover {
        background-color: rgba(255, 0, 0, 0.15);
        border: 1px solid rgba(255, 0, 0, 0.25);
      }

      img {
        width: 25px;
      }
    }
  }
}