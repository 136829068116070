.skeleton-container {
    display: block;
    margin-bottom: 0;
    position: relative;
    animation: flash 2s infinite ease-in-out;
    background-color: rgba(122, 180, 243, 0.28);
    border-radius: 6px;
    height: 100%;
    width: 250px;

    &.grid {
        display: flex;
        width: calc(50% - 10px); /* Three items per row, adjust the percentage as needed */
    }
}

@keyframes flash {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.3; }
}

@media screen and (min-width: 1024px) {

    .skeleton-container {
        min-height: 200px;
        width: 250px;

        &.grid {
            width: calc(20% - 10px);
        }
    }

}