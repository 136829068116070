.add-to-cart-container {
  .or {
    width: 100%;
    position: relative;
    height: 75px;

    .line {
      background-color: rgba(171, 166, 166, 0.38);
      position: absolute;
      top: 50%;
      height: 1px;
      width: 100%;
    }

    span {
      font-size: 1em;
      background-color: #eaeaea;
      width: auto;
      position: absolute;
      left: 45%;
      line-height: 75px;
      padding: 0 12px;
    }
  }
}

.product {
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: none;

  .column {
    .box {
      h2 {
        padding-top: 14px;
      }
    }

    .thickness, .rope-length {
      display: flex;

      p {
        flex: 2;
        font-weight: bold;
      }

      .select-picker {
        flex: 3;

        select {
          font-size: 1.3em;
          font-weight: bold;
          border: 0;
          -webkit-appearance: none; /* Removes default styling for iOS and Safari */
          -moz-appearance: none; /* Removes default styling for Firefox */
          appearance: none; /* Removes default styling for modern browsers */
          display: block;
          width: 100%; /* Responsive width */
          height: 100%;
          text-align: center;
        }
      }

      .radio-picker {
        display: none;
      }
    }

    .rope-addons, .halter-size {
      display: flex;
      flex-direction: column;
      margin-top: 12px;

      p.options-title {
        font-weight: bold;
        font-family: "Unica One", sans-serif;
        font-size: 1.5em;
      }

      label {
        margin: 0;
        padding: 0 15px;
        display: block;
        flex: 1;
        border-bottom: 1px solid #f8f8f8;
        position: relative;

        &:hover {
          background-color: rgba(124, 176, 238, 0.08);
          opacity: 1;
          background-repeat: no-repeat;
          background-position: 100% 50%;
          cursor: pointer;
        }

        input {
          appearance: none;
          padding: 0;
          margin: 0;
          cursor: pointer;

          &[type="text"] {
            border: 1px solid #9a9a9a;
          }

          + span {
            display: inline-block;
            padding: 15px 0 15px 30px;
            text-align: left;
            background-image: url("../assets/checkmark_off.png");
            background-repeat: no-repeat;
            background-position: 0 50%;
            background-size: 25px 25px;

          }

          &:checked + span {
            background-image: url("../assets/checkmark.png");
            opacity: 1;
            font-weight: bold;
            background-repeat: no-repeat;
            background-position: 0 50%;
            cursor: pointer;
            color: #0f69ad;
          }
        }
      }
    }

    .halter-size {
      display: flex;

      label {
        padding: 8px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: #FFFFFF;

        span {
          display: inline-block;
          flex: 1;
          max-width: 200px;
        }

        input {
          padding: 8px;
          flex: 1;
          margin-left: 10px;
        }
      }


    }

    .rope-color {
      text-align: center;
      background-color: #fff;
      font-weight: bold;
      flex: 1;

      p {
        font-size: 1.3em;
        margin-top: 18px;
      }
    }

    .rope-price {
      background-color: #ffffff;
      text-align: center;

      .price-name {
        padding: 12px 0 0 0;
        margin: 0;
        font-size: 3em;
        border-top: 1px solid rgba(154, 154, 154, 0.2);

        span {
          font-weight: bold;
        }
      }

      @keyframes shake {
        0%, 100% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(-20deg);
        }
        75% {
          transform: rotate(20deg);
        }
      }

      .add-to-cart {
        cursor: pointer;
        text-align: center;
        display: flex;
        padding-bottom: 3px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        height: 50px;
        border-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        color: rgba(52, 5, 5, 0.65);
        background-color: rgba(92, 185, 17, 0.75);
        font-size: 1.7em;
        font-variant: all-small-caps;

        &:hover {
          background-color: rgba(26, 224, 22, 0.61);
          color: #000;

          .cart-img {
            animation: shake 0.5s linear infinite; // Run the shake animation for 0.5 seconds
          }
        }

        &:active {
          color: rgb(255, 255, 255);
          background-color: rgba(24, 124, 199, 0.7);
          border: 1px solid rgba(17, 87, 140, 0.17);
          padding-bottom: 1px;

          .cart-img {
            animation: shake 0.3s linear infinite; // Run the shake animation for 0.5 seconds
          }
        }

        .cart-img {
          width: 25px; // Width of the background image
          height: 25px; // Height of the background image
          background-image: url("../assets/cart_add.png");
          background-size: 25px 25px;
          background-repeat: no-repeat;
          background-position: 0 50%;
          transition: transform 0.1s;
          margin-right: 5px;
        }
      }
    }

    .lg-react-element {
      display: flex;
      flex-direction: row;
      gap: 0;
      box-sizing: border-box;
      padding-left: 3px;

      .product-img {
        width: calc(100% - 5px);
        height: auto;
        max-height: 120px;
        border: 1px solid rgb(131, 131, 131);
        object-fit: cover;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .box {
      background-color: #fff;
    }

    p {
      margin: 14px;
    }

    .standard-link {
      color: #0e62b6;
      font-weight: bold;
      text-decoration: none;
      font-variant: small-caps;

      &:hover {
        text-decoration: underline;
        color: #053e6c;
      }
    }

    .description-content {

      h2 {
        font-size: 2em;
      }

      p {
        font-size: 1.2em;
        margin-bottom: 32px;

        a {
          color: #0e62b6;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            color: #053e6c;
          }
        }
      }
    }

    .radio-picker-title {
      font-size: 1.3em;
      font-family: "Unica One", sans-serif;
      margin-bottom: 0;
    }

    .options-picker {
      display: flex;
      flex-direction: column;

      input {
        flex: 1;
      }
    }

    .popper-detail {
      align-items: center;
      justify-items: center;
      align-content: center;
      background-color: #FFFFFF;
      text-align: left;
      width: 100%;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      &.invisible {
        display: none !important;
      }

      &.visible {
        display: block;
        animation: fadeIn 0.5s ease-in-out; /* Adjust the duration and easing as needed */
      }

      .popper-detail-desc {
        font-size: 1em;
        text-align: left;
        margin: 12px 0 0 0;
        padding: 12px 36px 6px 24px;
      }

      .graver-input {
        width: 75%;
        font-size: 1em;
        padding: 6px;
        margin: 0 0 24px 24px;
      }

      textarea {
        width: 96%;
        margin-left: 2%;
        margin-bottom: 12px;
        height: 100px;
        padding: 12px;
        font-size: 0.9em;
        font-family: sans-serif;
        box-sizing: border-box;
        border: 1px solid rgba(15, 105, 173, 0.26);
      }
    }
  }

  @media (min-width: 1024px) {
    gap: 12px;
    flex-direction: row;
    margin: 32px 5%;

    .column {

      .options-picker {
        flex-direction: row;
        text-align: center;
      }

      .popper-detail {

        .graver-input {
          width: 50%;
        }
      }

      .thickness, .rope-length {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          flex: 1;
        }

        .mobile-only {
          display: none;
        }

        .radio-picker {
          flex: 3;
          display: flex;
          flex-direction: row;
          align-items: center;

          label {
            border-right: 1px solid #c0c0c0;
            display: block;

            &:last-of-type {
              border: 0;
            }

            input {
              appearance: none;

              + span {
                padding-left: 20px;
                width: 75px;
                text-align: center;
                background-size: 25px 25px;
                display: inline-block;
                line-height: 25px;
                background-image: url("../assets/checkmark_off.png");
                background-position: 0 50%;
                background-repeat: no-repeat;
                font-size: 1em;
              }

              &:hover {
                background-image: url("../assets/checkmark.png");
                background-repeat: no-repeat;
                opacity: 1;
                cursor: pointer;
              }

              &:checked + span {
                opacity: 1;
                background-image: url("../assets/checkmark.png");
                color: #0e62b6;
                font-weight: bold;
              }
            }
          }
        }
      }

      .description-photos.single {
        margin-bottom: 5px;
      }

      // styling for description component
      .box {

        h2 {
          padding-top: 0;
          text-align: left;
          margin-left: 14px;
        }

        &.description {
          display: flex;

          .description-content {
            order: 2;
            flex: 1;
            padding: 0 32px;

            h2 {
              font-size: 3em;
              font-family: "Unica One", sans-serif;
              margin: 22px 14px 8px 14px;
            }

            p {
              font-size: 1.2em;

              .green {
                color: #1f720d;
              }

              a {
                color: #0e62b6;
                font-weight: bold;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                  color: #053e6c;
                }
              }
            }
          }

          .lg-react-element {
            order: 1;
            width: 260px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 5px;
            margin: 5px 0 0 5px;
            box-sizing: border-box;
            padding-left: 0;

            .product-img {
              border: 1px solid rgb(131, 131, 131);
              object-fit: cover;
              max-height: 240px;

              &:hover {
                opacity: 0.8;
              }
            }

            a:first-of-type {
              width: 260px;
              line-height: 0;

              .product-img {
                width: 100%;
                height: 280px;
              }
            }

            a:not(:first-of-type) {
              width: calc(250px / 3);

              .product-img {
                width: 100%;
                height: 90px;
              }
            }
          }
        }
      }

      &.column-left {
        flex: 0 0 70%; // 70% of the container width
        .box {
          flex: 1;
          background-color: #fff;
        }
      }

      &.column-right {
        flex: 0 0 30%; // 30% of the container width
        .box {
          height: 100%;

        }
      }
    }

    .add-to-cart {
      font-size: 1.3em !important;
    }
  }


}