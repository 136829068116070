.basket {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  gap: 12px;

  h2 {
    font-size: 1.8em;
    text-align: center;
    padding: 18px;
    margin: 32px 0;
  }

  .column-left {
    background-color: #fff;
  }

  a {
    color: #053e6c;
  }

  .column-right {
    background-color: #fff;

    .basket-summary-container {
      margin: 12px 0;
    }

    .basket-summary {
      display: flex;
      align-items: center;
      margin: 0 12px;

      &.sum {
        border-top: 1px solid #d7d7d7;
      }

      h3 {
        font-size: 1em;
        text-align: left;
        padding: 18px;
        margin: 0;
        flex: 1.4;
      }

      p {
        flex: 1;
        font-size: 1.2em;
        padding: 0;
        margin: 0;
        text-align: left;

        &.total-price-sum {
          font-weight: bold;
          font-size: 1.7em;
        }

        span {
          font-size: 0.7em;
        }
      }

      &.buttons {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;

        .basket-button {
          display: block;
          width: 90%;
          margin-top: 16px;
          padding: 12px 0;
          text-decoration: none;
          color: #fff;
          background-color: #27a442;
          font-variant: small-caps;
          font-size: 1.2em;

          &:hover {
            background-color: #14802b;
          }

          &:active {
            background-color: #14802b;
          }

          &.light {
            background-color: transparent;
            color: #707070;

            &:hover {
              color: #464646;
            }

            &:active {
              color: #000;
            }
          }
        }
      }
    }


  }
}

@media (min-width: 1025px) {
  .basket {
    flex-direction: row;
    padding: 24px 8%;

    .column-left {
      flex: 2;
    }

    .column-right {
      background-color: #fff;
      flex: 1;
    }
  }
}
