.mobile-menu-background {
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 997;
  overflow: hidden;
}

.menu-container {
  height: 0;
}

.menu {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 998;

  .menu-content {
    display: none;

    &.mobile-visible {
      background: rgb(213, 231, 250);
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 999;

      a {
        font-size: 1.1em;
        line-height: 40px;
        font-weight: bold;
        border-bottom: 1px solid rgba(155, 177, 192, 0.8);
        color: #000;

        &.active {
          font-weight: bold;
          border-bottom: 1px solid rgba(255, 255, 255, 0.22);
          border-right: 1px solid rgba(255, 255, 255, 0.22);
          border-left: 1px solid rgba(255, 255, 255, 0.22);
          background: rgba(7, 69, 126, 0.71);
          color: #eaeaea;
        }
      }
    }
  }

  .mobile-menu-button {
    background: rgba(7, 69, 126, 0.71);
    color: #fff;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    padding: 3px;
    font-size: 1.1em;

    &.active {
      background: rgba(7, 69, 126, 0.5);
    }

    p {
      background-image: url("../../assets/menu-white.png");
      background-size: 40px 28px;
      background-repeat: no-repeat;
      background-position-y: center;
      padding-left: 50px;
      padding-right: 20px;
      margin: 0 12px 5px 12px;
      font-size: 2em;
      font-weight: bold;
      font-variant: all-small-caps;
    }

    &:focus, &:active {
      background: rgba(30, 129, 213, 0.55);
    }
  }
}

.menu a {
  font-size: 0.9em;
  color: #cecece;
  text-decoration: none;
  padding: 5px 15px;
  border-bottom: 1px solid transparent;
}

.menu a:hover {
  color: #fff;
  border-bottom: 1px solid rgba(194, 191, 191, 0.68);
  background: rgba(196, 196, 196, 0.17);
}

.menu a.active {
  color: #fff;
  background: rgba(103, 187, 255, 0.24);
  border-bottom: 1px solid #58a5ff;
}

.menu_logo {
  display: none;
}

@media screen and (min-width: 1024px) {
  .menu-container {
    display: block;
    top: 0;
    text-align: center;
    margin: 0 auto;
    position: fixed;
    width: 100vw;
    background: rgba(0, 0, 0, 0.09);
    z-index: 999;
    height: auto;
    min-height: 50px;

    .menu_logo {
      display: block;
      text-decoration: none;
      border: 0;
      cursor: pointer;
      width: 200px;
      position: absolute;
      top: 15px;
      left: 10%;
      z-index: 999;

      img {
        width: 200px;
      }
    }
  }

  .menu {
    margin: 0 auto;
    width: 100%;
    text-align: right;
    top: 0;
    right: 10%;
    padding: 12px 0 12px 0;

    .mobile-menu-button {
      display: none;
    }

    .menu-content {
      display: block;
    }
  }

  .menu a {
    font-size: 1.1em;
    color: #ececec;
    text-decoration: none;
    padding: 14px 35px 10px 35px;
    transition: 0.5s;

    .basket-count {
      text-align: center;
      display: inline-block;
      background-color: #2cef00;
      font-weight: bold;
      width: 18px;
      height: 18px;
      color: #000000;
      border-radius: 12px;

      &.disabled {
        background-color: #656565;
      }
    }
  }

  .menu a:hover {
    color: #fff;
    border-bottom: 3px solid rgba(194, 191, 191, 0.68);
    background: rgba(196, 196, 196, 0.17);
  }

  .menu a.active {
    color: #ffffff;
    border-bottom: 0;
    background-color: rgba(0, 183, 255, 0.1);
    border-bottom: 3px solid rgba(0, 183, 255, 0.53);
  }
}