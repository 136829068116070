.header {
  background-position: 50% 30%;
  background-size: cover;
  background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0), rgb(0, 0, 0, 0), rgb(0, 0, 0, 0), rgb(0, 0, 0)),
  linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0), rgb(0, 0, 0, 0), rgb(0, 0, 0, 0), rgb(0, 0, 0)),
  url("../assets/header.jpg");
  height: 50vh;
  text-align: center;
}

.header {
  &.mini {
    height: 120px;
    background-position: 55% 10%;
    background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.5), rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3), rgb(0, 0, 0)),
    linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0), rgb(0, 0, 0)),
    url("../assets/header.jpg");
    background-size: cover, /* Size for the first gradient */
    cover, /* Size for the second gradient */
    150% 150% !important; /* Size for the image */

    h1 {
      margin: 12px 0;
    }

    .logo {
      animation: logo-intro 1s ease-in-out;
      width: 55%;
    }
  }

  @keyframes logo-intro {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .logo {
    animation: logo-intro 1s ease-in-out;
    width: 75%;
  }

  .header_content {
    margin: 0 auto;
    height: 100%;
    display: flex;
    color: white;

    h1 {
      text-align: center;
      font-variant: small-caps;
      position: relative;
      top: 50%
    }
  }
}

@media screen and (min-width: 1024px) {

  .header {
    background-repeat: no-repeat;
    background-position: 50% 0;
    height: 800px;
    background-size: auto 100%;
    text-align: center;

    &.mini {
      height: 250px;
      background-position-y: 12%;
      background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0), rgb(0, 0, 0, 0), rgb(0, 0, 0, 0), rgb(0, 0, 0)),
      linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.8), rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.5)),
      url("../assets/header.jpg");

      background-size: cover, cover, cover !important;

      .header_content {
        display: none;
      }
    }
  }

  .logo {
    width: 90%;
  }

  .header .header_content {
    margin: 0 auto;
    max-width: 85%;
  }

  .header .header_content h1 {
    margin: 50px 0 0 0;
  }
}
