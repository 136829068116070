@keyframes poster-intro {
  0% { opacity: 0; }
  30% { opacity: 0; }
  100% { opacity: 1; }
}

.poster-container {
  background: none;
  font-family: "Unica One", sans-serif;
  font-weight: 400;
  font-style: normal;
  animation: poster-intro 1s ease-in-out;

  h1 {
    font-size: 1.9em;
    margin: 0;
    padding: 0;
    color: #bbeaf5;
  }

  h2 {
    font-size: 1.5em;
    margin: 8px 0 0 0;
    padding: 0;
    color: #3b90d0;
  }
}

h1 {
  &.category-header {
    font-family: "Unica One", sans-serif;
    font-size: 3.2em;
    text-align: center;
    color: #174c75;
    text-shadow: rgba(13, 10, 10, 0.35) 1px 1px 2px;
    padding: 100px 0 12px 0;
    margin: 0;

    &.dog {
      color: #d2b412;
      text-shadow: rgba(13, 10, 10, 0.55) 2px 2px 3px;
    }
  }
}

.for-animal-container {
  width: 100%;
  background-size: cover;
  margin-top: 30px;
  margin-bottom: 80px;

  &.horses {
    background: linear-gradient(to bottom, #eaeaea, rgba(0, 0, 0, 0), #eaeaea),
    url("../assets/main/horses-mobile.jpg") no-repeat 80% 1px;
  }

  &.dogs {
    background: linear-gradient(to bottom, #eaeaea, rgba(0, 0, 0, 0), #eaeaea),
    url("../assets/main/dog-mobile.jpg") no-repeat 80% 1px;
  }

}

.products-animals {
  width: 90%;
  display: flex;
  gap: 12px;
  margin: 0 auto;
  flex-direction: column;
  position: relative;

  @keyframes fade-in {
    0% {
      opacity: 0
    }
    50% {
      opacity: 0.3
    }
    100% {
      opacity: 1
    }
  }

  .box {
    animation: fade-in 0.5s ease-in;
    animation-fill-mode: forwards;
    opacity: 0;
    width: 100%;
    margin-bottom: 20px;
    height: 150px;
    position: relative;
    box-sizing: border-box;
    background-size: cover;
    background-position: 50% 50%;
    box-shadow: 3px 3px 5px rgba(3, 42, 82, 0.45);

    p {
      font-family: "Unica One", sans-serif;
      text-decoration: none;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 8px 0;
      background-color: rgba(5, 62, 108, 0.76);
      color: #fff3d6;
      font-size: 1.5em;

      &.dog {
        background-color: rgba(231, 200, 16, 0.85);
        color: rgb(3, 11, 38);
      }
    }

    &.cordeo {
      animation-delay: 0.6s;
      background-image: url("../assets/cordeo.jpg");

      &.more {

        p {
          padding: 12px 0;
          border-radius: 8px;
          font-weight: bold;
        }
      }

    }

    &:hover {
      box-shadow: 3px 3px 5px rgba(3, 42, 82, 0.8);
    }

    &.smycz_red {
      background-image: url("../assets/smycz_red.jpg");
    }

    &.smycz_blue {
      background-image: url("../assets/smycz_blue.jpg");
    }

    &.obroza {
      background-image: url("../assets/obroza.jpg");
    }

    &.wodze {
      background-image: url("../assets/wodza.jpg");
    }

    &.halter {
      background-image: url("../assets/halter.jpg");
      animation-delay: 0.15s;
    }

    &.liny {
      background-image: url("../assets/lina.jpg");
      animation-delay: 0.30s;
    }

    &.uwiaz {
      animation-delay: 0.45s;
      background-image: url("../assets/uwiaz.jpg");
    }
  }
}

@media(min-width: 1024px) {


  .poster-container {
    width: 100%;

    h1 {
      font-size: 5em;
      margin: -115px 0 0 0;
      padding: 0;
      color: #b2dcff;
    }

    h2 {
      font-size: 4em;
      margin: 30px 0 0 0;
      padding: 0;
      color: #0f69ad;
    }

    h3 {
      font-size: 6.9em;
      padding: 0;
      margin: 10px 0 0 0;
      color: #0484f1;
    }

  }

  h1 {
    &.category-header {
      font-family: "Unica One", sans-serif;
      font-size: 6em;
      position: relative;
      top: -20px;
      margin: 0 auto;
      padding: 0;
      text-align: center;
    }
  }

  .for-animal-container {
    width: 100%;
    background-size: 1974px 700px;
    margin-top: 180px;
    margin-bottom: 80px;
    height: 700px;
    position: relative;

    &.horses {
      background: linear-gradient(to bottom, #eaeaea, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #eaeaea),
      url("../assets/main/horses.jpg") no-repeat 100% 0;
    }

    &.dogs {
      background: linear-gradient(to bottom, #eaeaea, rgba(0, 0, 0, 0), #eaeaea),
      url("../assets/main/dogs.jpg") no-repeat 0 100%;
    }

    .products-animals {
      top: 330px;
      gap: 32px;
      margin: 0 auto;
      flex-direction: row;

      .box {
        flex: 1;
        margin-bottom: 20px;
        height: 220px;

        p {
          font-size: 1.7em;
        }

        &.cordeo {
          animation-delay: 0.6s;
          background-image: url("../assets/cordeo.jpg");
        }

        &.more {
          height: 220px;
          margin-top: 0;
          background-image: url("../assets/cordeo.jpg");

          p {
            margin: 0;
            padding: 8px 0;
            border-radius: 0;
            font-weight: normal;
          }
        }
      }
    }
  }
}



