.privacy-policy {
  text-align: left;
  padding-bottom: 24px;

  h1 {
    text-align: left;
    font-size: 1.3em;
    padding: 12px;
  }

  h2 {
    text-align: left;
    font-size: 1.1em;
    padding: 12px 24px 6px 24px;
    margin: 0;
  }

  p {
    font-size: 0.9em;
    margin: 0 0 24px 0;
    padding: 0 24px;
  }

  ul, li {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}