.contact-container {
    width: 100%;
    margin: 18px 0 32px 0;
}

.contact-container section {
    background-color: #fff;
    margin-bottom: 12px;
    padding: 18px;
}

.contact-container section a {
    color: #0e62b6;
    text-decoration: none;
    cursor: pointer;
}

.contact-container section a:hover {
    color: #032d7a;
    text-decoration: underline;
}

.contact-container section h1 {
    font-size: 1.2em;
}

.contact-container section p {
    font-size: 1em;
    width: 85%;
    margin: 0 auto;
    padding: 0 0 12px 0;
    text-align: left;
}

@media screen and (min-width: 1024px) {

    .contact-container {
        padding: 0;
    }

    .contact-container section {
        padding: 18px 8%;
    }

    .contact-container section h1 {
        font-size: 2em;
    }

    .contact-container section p {
        font-size: 1.2em;
        width: 100%;
        padding: 0 0 24px 0;
    }

}