.orderError {
  max-width: 80%;
  padding: 0;
  background: #eee;

  h2 {
    padding: 18px !important;
    background-color: rgb(143, 0, 0);
    color: rgba(255, 255, 255, 0.79);
  }

  p {
    text-align: justify;
    padding: 12px 24px 24px 24px;
    margin: 0;
    font-size: 1.2em;
    line-height: 1.6em;
  }
}

@keyframes loadingAnimation {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(1.1) rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}

.order-info {
  display: flex;
  gap: 12px;
  justify-content: center;
  background: transparent;
  width: 100%;
  margin-top: 18px;
  margin-bottom: 32px;

  h2 {
    color: #044e77;
    margin-top: 18px;
    padding: 12px 0 32px 0 !important;
  }

  .loading {
    background: #fff;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.4em;
    padding: 36px 12px;
    width: 100%;
  }

  p {
    padding: 0;
    margin: 12px 5%;
    font-weight: bold;
  }

  .imgLoading {
    width: 80px;
    margin: 24px;
    animation: loadingAnimation 5s linear infinite;
  }
}

.payment-details {
  display: flex;
  gap: 12px;
  flex-direction: column;

  .order-id-info, .payments {
    background: #fff;
    text-align: left;
    padding: 0 12px;

    p {
      font-weight: normal;
      font-size: 1.1em;
      margin-bottom: 18px;

      span {
        font-weight: bold;
      }

      small {
        font-weight: bold;
      }
    }
  }
}

@media (min-width: 1024px) {
  .order-info {

    .order-id-info, .payments {
      margin: 0 48px;
      padding: 0 30px 18px 30px;
    }

    .loading {
      font-size: 1.3em;
    }

  }
}