.order-basket-list {
  margin: 0 0 18px 0;

  .order-item {
    gap: 12px;
    margin: 12px 0;
    padding: 0;
    background-color: #FFFFFF;
    border: 3px solid rgba(15, 105, 173, 0.52);

    img {
      max-width: 200px;
      flex: 1;
      object-fit: cover;
    }

    .order-item-base-info {
      display: flex;
      flex-direction: column;
      flex: 1;

      .order-item-product-name {
        font-family: sans-serif;
        font-size: 1.2em;
        color: #053e6c;
        margin: 12px 4px;
      }

      .order-item-table {
        flex: 1;
        border-spacing: 0;

        th {
          text-align: center;
          padding: 8px 0;
          border-left: 2px solid #e1e1e1;
          border-bottom: 2px solid #e1e1e1;

          &:first-of-type {
            border-left: 0;
          }
        }
      }

      .order-item-additional-info {
        display: flex;
        flex-direction: row;
        gap: 3px;
        background-color: rgba(225, 225, 225, 0.39);
        border-left: 2px solid #e1e1e1;

        .item-detail-content {
          flex: 1;
          background-color: rgba(255, 255, 255, 0.45);

          .row {
            display: flex;
            padding: 8px 0;

            p {
              flex: 1;
              font-weight: bold;
              font-size: 0.9em;
              text-align: center;
            }

            .green {
              color: #1f720d;
            }

            .blue {
              color: #0c67bb;
            }

            .red {
              color: red;
            }

            .yellow {
              color: #a1a100;
            }
          }

          h6 {
            font-size: 1.2em;
            text-align: center;
            padding: 8px 0;
            margin: 0;
            font-variant: all-small-caps;
          }

          p {
            margin: 8px 12px;
            padding: 0;
            font-size: 0.9em;
          }
        }
      }

    }
  }
}

@media screen and (min-width: 1024px) {
  .order-basket-list {
    margin: 0 0 18px 0;

    .order-item {
      display: flex;
    }
  }
}