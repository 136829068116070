.item-details {
  width: 95%;
  text-align: left;

  h2 {
    text-align: left;
    font-size: 2.5em;
    margin-top: 24px;
    color: #0f69ad;
  }

  h3 {
    margin-top: -5px;
    font-weight: normal;
    font-size: 1em;
    font-family: "Unica One", sans-serif;
  }

  h4 {
    font-family: "Unica One", sans-serif;
    font-size: 1.3em;
    margin: 24px 0 0 0;
    border-bottom: 1px solid #0f69ad;
    color: #0f69ad;
  }

  .item-price-info {
    display: flex;

    p {
      margin: 0;
      padding: 6px 0;
      font-size: 0.9em;
      flex: 1;
      text-align: center;
      border-left: 1px solid rgba(128, 128, 128, 0.61);

      &:first-of-type {
        border: 0;
      }
    }
  }

  .item-delivery-info {
    table {
      margin-top: 4px;

      tr, td {
        padding: 6px 12px;
      }

      td {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.7);
      }

      th {
        min-width: 200px;
        padding: 4px 12px;
        background-color: rgba(154, 154, 154, 0.25);
      }
    }
  }
}

.admin-advaced-options {
  width: 100%;
  border-top: 2px dashed #707070;

  button {
    padding: 8px 12px;
    margin: 18px 12px 12px 12px;
    font-family: "Unica One", sans-serif;
    font-size: 1.4em;
  }
}


@media screen and (min-width: 1024px) {
  .item-details {
    width: 1000px;
  }
}
