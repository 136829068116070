.delivery-form {
  max-width: 95%;
  margin: 18px auto;
  border: 1px solid #ccc;
  background-color: #f9f9f9;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      background-color: #0b4b8c;
      color: white;
      text-align: left;
      border: 1px solid #0f69ad;
      font-size: 0.9em;
      padding: 0 0 0 12px;
    }

    td {
      background-color: rgba(255, 255, 255, 0.9);
      border: 1px solid #0f69ad;
    }

    tr {
      border: 1px solid #0f69ad;
    }

    input {
      width: 100%;
      padding: 8px;
      border: 0;
      font-size: 1em;
      box-sizing: border-box;

      &:hover {
        background-color: #ffffff;
      }

      &.error {
        border-color: #ff4d4d;
      }
    }

    .error-message {
      color: #ff4d4d;
      font-size: 14px;
      margin-top: 4px;
    }
  }
}

@media (min-width: 1025px) {
  .delivery-form {
    max-width: 60%;
    margin: 18px auto;
    border: 1px solid #ccc;
    background-color: #f9f9f9;

    table {

      th {
        font-size: 1em;
        font-family: "Unica One", sans-serif;
        font-style: normal;
        max-width: 80px;
      }
    }
  }

}
