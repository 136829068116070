.main_box_grid {
    margin: 0 auto;
    background-color: #eeeeee;
    display: grid;
    grid-template-columns: repeat(1, [col] auto );
    grid-template-rows: repeat(1, [row] auto  );
    gap: 10px;
    padding: 10px;
}

.main-page-content {
    margin: -80px auto 0;
}

.offer-content {
    background-color: #eeeeee;
    padding: 8px 24px;
    margin-bottom: 18px;
    box-shadow: 0 2px 5px 0 #808080;
}

.offer-content h1 {
    font-size: 1.3em;
}

.offer-content h3 {
    font-size: 0.9em;
}

.offer-content h4 {
    font-size: 0.8em;
    font-weight: normal;
    color: #04478a;
    text-align: justify;
}

.offer-content h3 span {
    color: #0e62b6;
}

