.dopasowanie-container {
    width: 100%;
    margin: 0 auto;
}

.dopasowanie-container section {
    background-color: #eeeeee;
    margin-bottom: 18px;
    text-align: center;
    padding: 1px;
}

.dopasowanie-container section a {
    color: #0e62b6;
    text-decoration: none;
    cursor: pointer;
}

.dopasowanie-container section a:hover {
    color: #032d7a;
    text-decoration: underline;
}

.dopasowanie-container section h1 {
    font-size: 1.2em;
}

.dopasowanie-container section p {
    font-size: 0.9em;
    width: 85%;
    margin: 0 auto;
    padding: 0 0 12px 0;
    text-align: left;
}

.dopasowanie-container section img {
    width: 80%;
}

.red-strong {
    color: #b40606;
    font-weight: bold;
}

.blue-strong {
    font-weight: bold;
    color: #094bad;
}

.green-strong {
    font-weight: bold;
    color: #1f720d;
}

.yellow-strong {
    font-weight: bold;
    color: #b99600;
}

@media screen and (min-width: 1024px) {

    .dopasowanie-container {
        padding: 0;
    }

    .dopasowanie-container section {
        width: 100%;
        padding: 8px 24px;
    }

    .dopasowanie-container section h1 {
        font-size: 2em;
    }

    .dopasowanie-container section p {
        font-size: 1.2em;
        width: 80%;
        padding: 0 0 24px 0;
    }

    .dopasowanie-container section img {
        width: 350px;
        height: 350px;
        object-fit: cover;
        margin-bottom: 20px;
    }

}