.cookie-disclaimer {
  background-color: rgba(203, 150, 64, 0.9);
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;
  padding-bottom: 48px;
  max-width: 400px;
  background-image: url("../assets/cookie.png");
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 42px 0 0 0;
  padding-left: 92px;
  padding-right: 12px;
  max-height: 125px;

  a {
    color: #000;
  }

  p {
    text-align: justify;
    font-size: 0.9em;
  }

  .cookie-buttons {
    display: flex;
    gap: 24px;

    p {
      flex: 1;
      text-align: center;
      padding: 12px;
      border: 1px solid rgb(159, 114, 42);
      border-radius: 6px;
      font-size: 0.8em;
      cursor: pointer;

      &:hover, &:focus, &:active {
        border: 1px solid rgb(93, 60, 5);
        color: #ffffff;
      }
    }

    .accept {
      background-color: rgb(134, 94, 29);
      color: #ffe39e;
      font-weight: bold;
    }

    .decline {
      background-color: rgba(203, 150, 64, 1);
      color: #444040;
    }
  }
}