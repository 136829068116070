.admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .order-item {
    width: 90%;
    border: 3px solid rgba(15, 105, 173, 0.31);
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 16px;

    &.closed {
      opacity: 0.2;
      border: 2px solid #e1e1e1;
    }

    &.created {
      border: 4px solid #71d25d;
      box-shadow: 2px 3px 2px rgba(25, 154, 35, 0.32);
    }

    .item-left {
      padding-bottom: 12px;

      .item-id {
        font-family: "Unica One", sans-serif;
        font-size: 1.3em;
        color: rgb(11, 59, 98);
        border-bottom: 2px solid rgb(30, 146, 241);
        margin: 12px 24px;
        padding-top: 6px;
      }

      .item-info {
        margin: 6px 24px;
      }
    }

    .item-right {
      display: flex;
      flex-direction: row;

      .item-status {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 6px 0;

        strong {
          font-family: sans-serif;
          font-variant: all-small-caps;
          font-size: 1em;
        }

        .status-select {
          font-family: "Unica One", sans-serif;
          font-size: 1em;
          padding: 6px 24px;
          text-align: center;
          border: 1px solid #e1e1e1;
        }
      }

      .item-more {
        flex: 1;
        font-family: "Unica One", sans-serif;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        align-content: center;
        background-color: #0f69ad;
        color: #FFFFFF;
        font-variant: normal;
        padding: 0 12px;
        margin: 0;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-color: #1f720d;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {

  .admin {
    .order-item {
      width: 80%;
      max-width: 800px;
      flex-direction: row;

      .item-left {
        flex: 2;
      }

      .item-right {
        flex: 1;
        flex-direction: column;
      }
    }
  }
}
