.gallery-container .lg-react-element  {
    padding: 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 5px;
}

.gallery-container  .lg-react-element  a {
    display: block;
    width: calc(50% - 10px);
    margin-bottom: 0;
    position: relative;
}

.gallery-container .lg-react-element a img {
    width: 100%;
    height: 180px;
    display: block;
    object-fit: cover;
}

.gallery-container .lg-react-element a span {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    padding: 6px 0;
    font-size: 0.9em;
    color: #FFF;
    background-color: rgba(0, 56, 110, 0.78);
    border-top: 1px solid rgba(0, 56, 110, 0.9);
    bottom: 0;
}

@media screen and (min-width: 1024px) {

    .gallery-container  .lg-react-element {
        padding: 14px;
    }

    .gallery-container  .lg-react-element  a {
        width: calc(20% - 10px); /* Three items per row, adjust the percentage as needed */
    }

    .gallery-container  .lg-react-element  img {
        height: 200px;
        object-fit: cover;
    }
}