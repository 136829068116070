.content {
  margin: 0 auto;
  font-size: 1em;
  max-width: 1900px;
}

.content-section {
  margin: 0 24px;
  padding: 8px 10%;
}

.content-section.center {
  text-align: center;
}

.content-table {
  width: 100%; /* Full-width tables */
  border-collapse: collapse; /* Removes the space between borders */
  table-layout: auto;
  font-size: 0.85em;
}

.content-table th, .content-table td {
  padding: 8px; /* Spacing inside cells */
  border-top: 1px solid rgba(115, 165, 185, 0.2);
  border-bottom: 1px solid rgba(115, 165, 185, 0.2);
}

.content-table th {
  font-weight: bold;
  color: #333; /* Dark text for contrast */
}

.content.pricelist h1 {
  font-weight: bold;
  margin: 12px 24px 24px 8px;
}

.content {

  h1 {
    text-align: center;
    font-size: 2.2em;
    font-family: "Unica One", sans-serif;
    font-style: normal;
    padding: 32px 0 12px 0 !important;
    margin: 0;
  }

  h2 {
    text-align: center;
    font-size: 1.3em;
    font-family: "Unica One", sans-serif;
    font-style: normal;
    padding: 12px 0 12px 0 !important;
    margin: 0;
  }
}

.content h3 {
  font-size: 0.9em;
  font-weight: bold;
}

.content ul {
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 12px 8px;
}

.content li {
  margin: 12px 0;
}

.content hr {
  border: 8px solid rgba(203, 203, 203, 0.62);
  margin: 0;
}

.content-table td:first-child {
  text-align: left;
}

.content-table th:first-child {
  background-color: #eee;
  text-align: right;
  border: 0;
}

.content-table td:nth-child(even), .content-table th:nth-child(even) {
  background-color: rgba(110, 191, 210, 0.1); /* Zebra-striping for rows */
}

.content-table tr:hover {
  background-color: rgba(206, 190, 128, 0.12); /* Zebra-striping for rows */
}

.no-margin {
  margin: -10px;
}

.content-section {
  h1 {
    font-size: 1.7em;
    margin: 0 0 12px 0;
  }
}

.content-section.palety {
  display: block;
  background: linear-gradient(rgba(215, 215, 215, 0.9), rgba(215, 215, 215, 0.9)), url("../assets/halter.jpg");
  background-size: cover;
  background-position-y: 30%;
  padding: 12px 8%;
  margin: 0;
}

.content-section.palety a {
  font-weight: bold;
  flex: 1;
  display: block;
  text-decoration: none;
  font-variant: all-small-caps;
  padding: 10px 0;
  margin: 15px 10px 5px 10px;
  background: rgba(25, 95, 155, 0.16);
  border-top: 1px solid rgba(15, 115, 138, 0.4);
  border-bottom: 1px solid rgba(15, 115, 138, 0.4);
  border-left: 0;
  border-right: 0;
  color: #044e77;
  font-size: 1.3em;
}

.content-section.palety a:hover {
  background: rgba(60, 147, 220, 0.29);
  border-top: 1px solid rgba(15, 115, 138, 0.4);
  border-bottom: 1px solid rgba(15, 115, 138, 0.4);
  border-left: 0;
  border-right: 0;
  color: #01324b;
  transition: 0.3s;
}

.content-section.palety p {
  font-weight: bold;
  text-align: left;
  font-size: 1.2em;
  flex-basis: 100%;
  width: 100%;
  padding: 0;
  margin: 10px;
}

@media screen and (min-width: 1024px) {

  .content {
    font-size: 1.1em;
    text-align: center;
  }

  .content-table {
    margin: 20px auto 0;
    font-size: 1.1em;
    max-width: 100%;

    &.short {
      max-width: 300px;
    }
  }

  .content h1 {
    font-size: 3em;
    font-weight: bold;
  }

  .content h2 {
    font-size: 1.4em;
    font-weight: bold;
    font-style: normal;
    padding: 0 !important;
    margin: 0;
  }

  .content h3 {
    font-size: 1.2em;
    font-weight: bold;
  }

  .content-table td:first-child {
    text-align: left;
    padding-left: 15px;
    width: 45%;
    font-weight: bold;
  }

  .content-table th:first-child {
    background-color: #eee;
    text-align: right;
    border: 0;
    padding-right: 20px;
  }

  .content p {
    margin-left: 24px;
    font-size: 1.1em;
  }

  .content ul {
    list-style: circle;
    padding: 0;
    margin: 24px 8px;
  }

  .content li {
    margin: 12px 34px;
    font-size: 1em;
  }

  .content-section {
    h1 {
      font-size: 2em;
    }
  }

  .content-section.palety {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .content-section.palety p {
    font-size: 1.3em;
    margin: 10px 10px 0 10px;
  }

  .content-section.palety a {
    font-weight: bold;
    flex: 1;
    display: block;
    text-decoration: none;
    font-variant: normal;
    padding: 9px 0 9px 0;
    background: none;
    border-top: 1px solid rgba(15, 115, 138, 0.4);
    border-bottom: 1px solid rgba(15, 115, 138, 0.4);
    border-left: 0;
    border-right: 0;
    color: #053e6c;
    margin-top: 0;
    font-size: 1.1em;
  }

  .content-section.palety a:hover {
    color: #002542;
    background: rgba(34, 150, 255, 0.2);
    border-top: 1px solid rgba(15, 115, 138, 0.4);
    border-bottom: 1px solid rgba(15, 115, 138, 0.4);
    border-left: 0;
    border-right: 0;
  }

}
