.content.shop {
  padding: 18px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .shop-box {
    width: 100%; // Default to full width for mobile
    margin-bottom: 20px; // Spacing between rows
    height: 220px;
    border-radius: 6px;
    border: 1px solid #c2b8b8;
    position: relative;
    box-sizing: border-box;
    background-size: 100%;
    background-position: 50% 50%;

    &:hover {
      box-shadow: 3px 3px 5px rgba(3, 42, 82, 0.31);
    }

    &.liny {
      background-image: url("../assets/lina.jpg");
    }

    &.halter {
      background-image: url("../assets/halter.jpg");
    }

    &.wodze {
      background-image: url("../assets/wodza.jpg");
    }

    &.cordeo {
      background-image: url("../assets/cordeo.jpg");
    }

    &.uwiaz {
      background-image: url("../assets/uwiaz.jpg");
    }

    &.smycz_red {
      background-image: url("../assets/smycz_red.jpg");
    }

    &.smycz_blue {
      background-image: url("../assets/smycz_blue.jpg");
    }

    &.obroza {
      background-image: url("../assets/obroza.jpg");
    }

    &.pricelist {
      background: linear-gradient(to bottom, rgba(15, 105, 173, 0.7), rgba(15, 105, 173, 0.6), rgba(15, 105, 173)), url("../assets/pricelist.jpg") 50% 25%;
      text-decoration: none;
      height: 60px;
      border: 1px solid #085791;
      background-size: cover;

      &:hover p {
        background: none;
      }

      p {
        transition: font-size 0.3s ease-in-out;
        display: block;
        background: none;
        text-align: center;
      }
    }

    p {
      font-family: "Unica One", sans-serif;
      position: absolute;
      bottom: 0;
      background-color: rgba(31, 110, 190, 0.85);
      margin: 0;
      color: #FFF;
      width: 100%;
      padding: 13px 13px;
      font-size: 1.7em;
      box-sizing: border-box;
      border-radius: 0 0 6px 6px;
      display: flex; /* Enable flexbox */
      justify-content: center; /* Center text horizontally (optional) */
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    }
  }
}

@media (min-width: 1025px) { // Media query for desktop and larger screens
  .content.shop {
    padding: 18px 8%;

    .shop-box {
      width: calc(33% - 10px); // Adjust width for desktop
      margin-right: 10px; // Add right margin for spacing
      height: 260px;
      transition: background-position 1s ease, box-shadow 0.5s ease; /* Transition for transform and background-size */

      &.pricelist {
        height: 260px;
        background-size: cover;
        border: 0;

        &:hover p {
          background: none;
          transition: font-size 0.5s ease-in-out;
          font-size: 2.5em;
        }
      }

      &:hover {
        transition: background-position 1s ease, box-shadow 0.5s ease; /* Transition for transform and background-size */
        background-position: 50% 75%;
      }

      &:nth-of-type(3n) {
        margin-right: 0; // Removes margin for every second element
      }

      p {
        position: absolute;
        bottom: 0;
        width: 100%;
        transition: background-color 2s ease, height 0.3s ease-in; /* Transition for returning to original state */
        justify-content: left; /* Center text horizontally (optional) */
        align-items: flex-end; /* Align text to the bottom */
      }

      &:hover p {
        transition: background-color 0.3s ease, height 0.3s ease-out; /* Transition for returning to original state */
        background-color: rgb(13, 83, 150);
      }
    }
  }
}